<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div style="height: inherit">
        <div class="body-content-overlay" :class="{'show': mqShallShowLeftSidebar}" @click="mqShallShowLeftSidebar = false" />
        <div class="todo-app-list">
    
            <!-- App Searchbar Header -->
            <div class="app-fixed-search d-flex align-items-center">
    
                <!-- Toggler -->
                <div class="sidebar-toggle d-block d-lg-none ml-1">
                    <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
                </div>
    
                <!-- Searchbar -->
                <div class="d-flex align-content-center justify-content-between w-100">
                    <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" class="text-muted" />
                        </b-input-group-prepend>
                        <b-form-input :value="searchQuery" placeholder="Search task" @input="updateRouteQuery" />
                    </b-input-group>
                </div>
    
                <!-- Dropdown -->
                <div class="dropdown">
                    <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item @click="resetSortAndNavigate">
                            Reset Sort
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
                            Sort A-Z
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
                            Sort Z-A
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
                            Sort Assignee
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
                            Sort Due Date
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
    
            <!-- Todo List -->
            <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area">
                <draggable v-model="tasks" handle=".draggable-task-handle" tag="ul" class="todo-task-list media-list">
                        <li >
    
                        <table class="table">
                            <thead>
                            <th>Task</th>
                            <th >Tag</th>
                            <th>Due Date</th>
                            <th>Owner</th>
                        </thead>
                        <tbody>
                            <!-- $route.path == '/apps/todo/pending' -->
                            <tr v-for="task in tasks" :key="task.id" class="todo-item" :class="{ 'completed': task.isCompleted }" @click="handleTaskClick(task)">
                                    <td>
                                    <div class="todo-title-area">
                                        <div class="title-wrapper">
                                            <b-form-checkbox :checked="task.isCompleted" @click.native.stop @change="updateTaskIsCompleted(task)" />
                                            <span class="todo-title">{{ task.title }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="badge-wrapper mr-1">
                                        <span v-for="tag in task.tags" 
                                        :key="tag.label" 
                                        style = "color: white"
                                        :style="{background:`${resolveTagVariant(tag.title)}`}" 
                                        class="badge">
                                                                                        
                                            {{ tag.label }}
                    
                                        </span>
                                    </div> 
                                </td>
                                <td>
                                    <small class="text-nowrap text-muted mr-1">
                                   <span class="badge badge-warning"  v-if="today.diff(moment(task.dueDate), 'days') > -3 && today.diff(moment(task.dueDate), 'days') <= -1">
                                       {{ formatDate(task.dueDate, { month: 'short', day: 'numeric'}) }}
                                   </span>
                                   <span class="badge badge-danger" v-else-if="today.diff(moment(task.dueDate), 'days') >= 0">
                                       {{ formatDate(task.dueDate, { month: 'short', day: 'numeric'}) }} 
                                   </span>
                                  
                                   <span class="badge badge-secondary"  v-else>
                                    <span v-if="task.dueDate == '0000-00-00'">
                                        --
                                    </span>
                                    <span v-if="task.dueDate != '0000-00-00'">
                                        {{ formatDate(task.dueDate, { month: 'short', day: 'numeric'}) }} 
                                    </span>
                                   </span>
                               </small>
                                </td>
                                <td>
                                    <span v-if="task.assignee.length">
                                    <b-avatar 
                                        v-for="assignee in task.assignee" 
                                        v-if="assignee.key"
                                        :key="assignee.key"
                                        size="32" 
                                        :src="assignee.fullName" 
                                        :variant="`light-${resolveAvatarVariant(task.tags)}`" 
                                        :text="avatarText(assignee.fullName)" />
                                </span>
                                <span v-else >
                                    <b-avatar size="32" variant="light-secondary">
                                        <feather-icon icon="UserIcon" size="16" />
                                    </b-avatar>
                                </span>
    
                                </td>


                               
                            </tr>
                        </tbody>
    
                    </table>
                    </li>
                    
                </draggable>
                <div class="no-results" :class="{'show': !tasks.length}">
                    <h5>No Items Found</h5>
                </div>
            </vue-perfect-scrollbar>
        </div>
    
        <!-- Task Handler -->
        <todo-task-handler-sidebar 
            v-model="isTaskHandlerSidebarActive" 
            :task="task" 
            :clear-task-data="clearTaskData" 
            @remove-task="onRemoveTask"
            @add-task="onAddTask"
            @update-task="onUpdateTask" 
            @markAsCompleted="updateTaskIsCompleted"
            :task-tags="tagOptionsHandler"
        />
    
        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <todo-left-sidebar 
                :task-tags="tagOptionsLeft" 
                :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive" 
                :class="{'show': mqShallShowLeftSidebar}" 
                @close-left-sidebar="mqShallShowLeftSidebar = false" 
                @fetchTagsEvent="fetchTags"
            />
        </portal>

        <b-button @click="toggleModal" class= "mr-2">Toggle Modal</b-button>
        <company-details-modal 
            v-if="showModal"
            :show-modal.sync="showModal"
            @submission-result="handleSubmissionResult"
            @close-modal="showModal = false"
        ></company-details-modal>
    </div>
</template>
    
    
<script>
    import store from '@/store'
    import {
        ref,
        watch,
        computed,
        onMounted,
        onUnmounted,
        getCurrentInstance,
    } from '@vue/composition-api'
    import {
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BBadge,
        BAvatar,
        BButton,
    } from 'bootstrap-vue'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import draggable from 'vuedraggable'
    import {formatDate, avatarText} from '@core/utils/filter'
    import {useRouter} from '@core/utils/utils'
    import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
    import TodoLeftSidebar from './TodoLeftSidebar.vue'
    import todoStoreModule from './todoStoreModule'
    import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
    import CompanyDetailsModal from './CompanyDetailsModal.vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import axios from 'axios'
    import { serverUri } from '@/config'
    import moment from "moment";
    import Vue from "vue";
    import { getUserData } from "@/auth/utils";

    window.EventBus = new Vue();
    
    export default {
        components: {
            BFormInput,
            BInputGroup,
            BInputGroupPrepend,
            BDropdown,
            BDropdownItem,
            BFormCheckbox,
            BBadge,
            BAvatar,
            draggable,
            VuePerfectScrollbar,
            BButton,
    
            // App SFC
            TodoLeftSidebar,
            TodoTaskHandlerSidebar,
            CompanyDetailsModal,
            ToastificationContent,
            

        },
        
        data() {
            return {

                //taskTags: [],
                //tagOptionsLeft: [],
                //tagOptionsHandler: [],
                today: null,
                todayFormat: null,
                
                
                //moveTasks : []
    
            }
        },
        beforeDestroy() {
            //EventBus.$off("getTasks");
        },
        created() {
            this.today = moment();
            this.todayFormat = moment().format();
            //this.fetchTags();
            
            //EventBus.$on("getTasks", () => {
              //  this.tasks = [];               
            //});
        },
        methods: {

            
            moment: function (date) {
                return moment(date).format('YYYY-MM-DD');
            
            },

            
        },
          
        setup() {
            // Register module
            const showModal = ref(false);
            const toggleModal = () => {
                showModal.value = !showModal.value;
            };
            const taskData = ref(null);
            const isTaskHandlerSidebarActive = ref(false);
            const taskTags = ref([]);
            const tasks = ref([]); //computed(()=> store.state['app-todo'].taskList);
            const tagOptionsLeft = ref([]);
            const tagOptionsHandler = ref([]);
            //const today = ref(new Date());
            //const todayFormat = ref(null);
            const moveTasks = ref([]);
            const TODO_APP_STORE_MODULE_NAME = 'app-todo';
            const {route, router} = useRouter();
            //const routeSortBy = computed(() => route.value.query.sort || 'latest');
            const routeParams = computed(() => route.value.params);
            const routeQuery = computed(() => route.value.query);
            const searchQuery = ref(route.value.query.q);//route.value.query.q || '');
            const filter = ref(route.value.params.filter || '');
            const sortBy = ref(route.value.query.sort || 'latest');
            const sortOptions = [
                'latest',
                'title-asc',
                'title-desc',
                'assignee',
                'due-date',
                'tag'
            ];            
            const blankTask = {
                id: null,
                title: '',
                recurring: {
                    option: 'No'
                },
                
                status: 'pending',
                dueDate: new Date(),
                type:null,
                custom_date:null,
                description: '',
                assignee: {fullName: getUserData().full_name, key: getUserData().id},
                tags: [],
                interval: 10,
                isPending: true,
                isInProgress: false,
                isCancelled: false,
                isCompleted: false,
            };
            const task = ref(JSON.parse(JSON.stringify(blankTask)));
            const instance = getCurrentInstance();

            const showToast = (variant, title, text) => {
                instance.proxy.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: 'BellIcon',
                        text: text,
                        variant,
                    },
                });
            }; 
            
            watch(routeParams, () => {
                console.log("Routeparams changed:", routeParams.value);
                fetchTasks();});
            watch(routeQuery, () => {
                console.log("Routequery changed:", routeQuery.value);
                fetchTasks();});
            watch(sortBy, () => {
                console.log("Sortby changed:", routeQuery.value.sort);
                fetchTasks();});          
                
            //const formatMoment = (date) => moment(date).format('YYYY-MM-DD');
            //

            if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule);

            const fetchTags = async () => {
                try{
                    const response = await axios.get(`${serverUri}/todo/tag/get`);
                    taskTags.value = response.data.map(item => ({ ...item }));
                    console.log(taskTags)
                    tagOptionsLeft.value = response.data.map(item => ({
                        color: item.color,
                        title: item.id,
                        label: item.title,
                        route: {
                            name: 'apps-todo-tag',
                            params: {tag: item.title}
                        } 
                    }));
                    tagOptionsHandler.value = response.data.map(item => ({
                        title: item.title,
                        label: item.title,
                    }));
                } catch (error){
                    console.log('Error fetching tags: ', error);
                }
            }

            const  fetchTasks = async () => {
                
                    try {
                        console.log('fetching tasks with filter', routeParams.value, 'and query: ', routeQuery.value.q);
                        console.log("sortby.value is", sortBy.value)
                        console.log("routequery.value.sort is ", routeQuery.value.sort)
                        tasks.value = await store.dispatch('app-todo/fetchTasks', {
                        q: routeQuery.value.q,
                        filter: routeParams.value.filter,
                        tag: routeParams.value.tag,
                        sortBy: routeQuery.value.sort,
                        });
                         
                        return tasks.value;
                        
                    }catch (error) {
                        console.error('Error fetching tasks:', error);
                        return [];
                    }
                
                
                 
            }
            const userData = ref(getUserData());

            const handleSubmissionResult = (result) => {
                if (result.success) {
                    showToast('warning', 'Submission registered',result.message );
                } else {
                    showToast('warning', 'Submission failed', result.message)
                }
            }

            const isUserRoleFive = computed(() => {
                    console.log("User role_id is " , userData.value.role_id);
                    return (userData.value.company_id === 0);
                });
            onMounted(()=> {

                if (isUserRoleFive.value && getUserData().company_id === 0) {
                    console.log("evaluating user role", isUserRoleFive.value);
                    showModal.value = true; // Ensure 'showModal' is part of your component's data
                }
                fetchTasks();
                fetchTags();
            });

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
            });
            
            
            const updateTaskIsCompleted = async (taskData) => {
            // eslint-disable-next-line no-param-reassign

                taskData.isPending = false;
                taskData.isCancelled = false;
                taskData.isInProgress = false;
                taskData.isCompleted = true;
                taskData.status = "completed";
                
                try {
                    await onUpdateTask(taskData);
                    fetchTasks();
                } catch (error) {
                    // Handle any errors that occur during onUpdateTask
                    console.error("Error in updateTaskIsCompleted:", error);
                }
            };

            const index = moveTasks.value.findIndex(item => taskData.id === item)

            if (index!== -1) {
                console.log('already exists');
                moveTasks.value.splice(index, 1)
            } else{
                moveTasks.value.push(parseInt(taskData.id));
            }

            localStorage.setItem('move_data', JSON.stringify(moveTasks.value));
               
            /*const updateTask = (taskData) => {
                store.dispatch('app-todo/updateTask', taskData)
            }*/
                   
            
            const resetSortAndNavigate = () => {
                const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))
    
                delete currentRouteQuery.sort
    
                router.replace({
                    name: route.name,
                    query: currentRouteQuery
                }).catch(() => {})
            }
            
    
            const onAddTask = val => {

                if (isUserRoleFive.value) {
                    console.log("evaluating user role", isUserRoleFive.value);
                    showToast('warning', 'Unavailable', 'You must register your company profile to use this feature');
                    return;
                }
                store.dispatch('app-todo/addTask', val)
                    .then(() => {
                        // eslint-disable-next-line no-use-before-define
                        fetchTasks();

                        
                    })
            }
            const onRemoveTask = () => {
                store.dispatch('app-todo/removeTask', {
                        id: task.value.id
                    })
                    .then(() => {
                        // eslint-disable-next-line no-use-before-define
                        fetchTasks();
                    })
            }
            const updateTaskInList = (tasksList, updatedTask) => {
                return tasksList.map(task => {
                    if (task.id === updatedTask.id) {
                        return { 
                            ...task, 
                            ...updatedTask,
                            status: updatedTask.isCompleted ? 'completed' : 'pending',
                            isCompleted: updatedTask.isCompleted,
                        };
                    }
                    return { ...task, isCompleted: task.status === 'completed' };
                });
            };

            const filterCompletedTasks = (tasksList, completedTaskIds) => {
                return tasksList.filter(task => !completedTaskIds.includes(String(task.id)));
            };

            const onUpdateTask = taskData => {
                console.log(taskData);
                store.dispatch('app-todo/updateTask', { task: taskData })
                    .then(updatedTask => {
                        fetchTasks();
                        return tasks.value;
                    })
                    .then(fetchedTasks => {
                        console.log("fetchedTasks:", fetchedTasks);

                        const uniqueTasks = [...new Set(fetchedTasks.map(x => x.id))].map(id => fetchedTasks.find(x => x.id === id));
                        
                        const completedTaskIds = (localStorage.getItem('move_data') || "").split(',');
                        
                        let updatedTasksList = updateTaskInList(uniqueTasks, taskData);

                        if (taskData.isCompleted) {
                            updatedTasksList = filterCompletedTasks(updatedTasksList, completedTaskIds);
                        }

                        tasks.value = updatedTasksList;
                    })
                    .catch(error => {
                        console.error("Error updating task:", error);
                    });
            };
            
            const clearTaskData = () => {
                task.value = JSON.parse(JSON.stringify(blankTask))
            }
    
            const perfectScrollbarSettings = {
                maxScrollbarLength: 150,
            }
    
            
    
            const resolveTagVariant = tag => {
                if (!Array.isArray(taskTags.value)) {
                    console.error("taskTags is not an array. Please check the data loading process.");
                    return 'primary'; // Return a default variant when taskTags is not an array
                }


                const foundTag = taskTags.value.find(tagObj => tagObj.title === tag);
                if (foundTag) {
                    return foundTag.color;
                } else {
                    if (tag === 'team') return 'primary';
                    if (tag === 'low') return 'success';
                    if (tag === 'medium') return 'warning';
                    if (tag === 'high') return 'danger';
                    if (tag === 'update') return 'info';
                    return 'primary'; // Handle the case where no matching tag is found
                }
            };


    
            const resolveAvatarVariant = tags => {
                
                if (tags.includes('high')) return 'primary'
                if (tags.includes('medium')) return 'warning'
                if (tags.includes('low')) return 'success'
                if (tags.includes('update')) return 'danger'
                if (tags.filter(tag => tag.label.includes('team'))) return 'info'
                return 'primary'
            }
    
            const updateRouteQuery = val => {
                const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))
                
                if (val) currentRouteQuery.q = val
                else delete currentRouteQuery.q
    
                router.replace({
                    name: route.name,
                    query: currentRouteQuery
                })
            }
        
    
            const handleTaskClick = taskData => {
                task.value = taskData
                isTaskHandlerSidebarActive.value = true
            }
    
            const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility();

            return {
                task,
                tasks,
                taskTags,
                tagOptionsLeft,
                tagOptionsHandler,
                moveTasks,
                fetchTags,
                //formatMoment,
                onRemoveTask,
                onAddTask,
                onUpdateTask,
                clearTaskData,
                searchQuery,
                fetchTasks,
                perfectScrollbarSettings,
                updateRouteQuery,
                resetSortAndNavigate,
                showModal,
                toggleModal,
                showToast,
                // UI
                resolveTagVariant,
                resolveAvatarVariant,
                isTaskHandlerSidebarActive,
    
                // Click Handler
                handleTaskClick,
    
                // Filters
                formatDate,
                avatarText,
    
                // Single Task isCompleted update
                updateTaskIsCompleted,
    
                // Left Sidebar Responsive
                mqShallShowLeftSidebar,
            }
        }
    }
        
</script>
<style lang="scss" scoped>
    .draggable-task-handle {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        visibility: hidden;
        cursor: move;
    
        .todo-task-list .todo-item:hover & {
            visibility: visible;
        }
    }
    
</style>

<style lang="scss">
    @import "~@core/scss/base/pages/app-todo.scss";
</style>
    