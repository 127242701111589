<template>
<div>
    <b-sidebar id="sidebar-task-handler" sidebar-class="sidebar-lg" :visible="isTaskHandlerSidebarActive" bg-variant="white" shadow backdrop no-header right @change="(val) => $emit('update:is-task-handler-sidebar-active', val)" @hidden="clearForm">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <b-button v-if="taskLocal.id" size="sm" :variant="taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'" @click="$emit('markAsCompleted', taskLocal)">
                    {{ taskLocal.isCompleted ? 'Completed' : 'Mark Complete' }}
                </b-button>
                <h5 v-else class="mb-0">
                    Add Task
                </h5>
                <div>
                    <feather-icon v-show="taskLocal.id" icon="TrashIcon" class="cursor-pointer" @click="$emit('remove-task'); hide();" />
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>
            </div>

            <!-- Body -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">

                <!-- Form -->
                <b-form class="p-2 task_form_add" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

                    <!-- Title -->
                    <validation-provider #default="validationContext" name="Title" rules="required">
                        <b-form-group label="Title" label-for="task-title">
                            <b-form-input id="task-title" v-model="taskLocal.title" autofocus :state="getValidationState(validationContext)" trim placeholder="Task Title" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Assignee -->
                    <validation-provider #default="validationContext" name="Assignee" rules="required">
                        <b-form-group label="Assignee" label-for="assignee">
                            <v-select v-model="taskLocal.assignee" multiple :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="userlist" :key="userlist.key" label="fullName" class="assignee-selector" input-id="assignee">

                                <template #selected-option="{ fullName }">

                                    <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                                </template>
                            </v-select>

                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Recurring -->
                    <!-- <b-form-group label="Recurring" label-for="recurring">
                        <v-select v-model="taskLocal.recurring" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="option" :options="option" />
                    </b-form-group> -->

                    <!-- Interval -->
                    <!-- <validation-provider :style="taskLocal.recurring.option === 'No' ? 'display: none' : 'display: block'" #default="validationContext" name="Interval" rules="required">
                        <b-form-group label="Interval" label-for="task-interval">
                            <b-form-input id="interval-title" v-model="taskLocal.interval" autofocus :state="getValidationState(validationContext)" trim placeholder="Interval" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider> -->

                    <!-- From Date -->
                    <validation-provider :style="taskLocal.recurring.option === 'No' ? 'display: none' : 'display: block'" #default="validationContext" name="Due Date" rules="required">

                        <b-form-group label="Due Date" label-for="from-date">
                            <flat-pickr v-model="taskLocal.dueDate" class="form-control" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                      <!-- Status -->
                      <b-form-group label="Status" label-for="status">
                        <v-select v-model="taskLocal.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="element" :options="status" />
                    </b-form-group>


                    <!-- To Date -->
                    <!-- <validation-provider :style="taskLocal.recurring.option === 'No' ? 'display: none' : 'display: block'" #default="validationContext" name="To Date" rules="required">

                        <b-form-group label="To Date" label-for="to-date">
                            <flat-pickr v-model="taskLocal.dueDate" class="form-control" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider> -->

                      <!-- Status -->
                  

                    <!-- due Date -->
                    <validation-provider :style="taskLocal.recurring.option === 'No' ? 'display: block' : 'display: none'" #default="validationContext" name="Due Date" rules="required">

                        <b-form-group label="Due Date" label-for="due-date">
                            <flat-pickr v-model="taskLocal.dueDate" class="form-control" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!--Tag -->
                    <b-form-group label="Tag" label-for="tag">
                        <v-select v-model="taskLocal.tags" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple :options="taskTags"  input-id="tags" />
                    </b-form-group>

                    <!-- Description -->
                    <b-form-group label="Description" label-for="task-description">
                        <quill-editor id="quil-content" v-model="taskLocal.description" :options="editorOption" class="border-bottom-0" />
                        <div id="quill-toolbar" class="d-flex justify-content-end border-top-0">
                            <!-- Add a bold button -->
                            <button class="ql-bold" />
                            <button class="ql-italic" />
                            <button class="ql-underline" />
                            <!-- <button class="ql-align" />
                            <button class="ql-link" /> -->
                        </div>
                    </b-form-group>

                    <b-form-group label="Repeat Task" label-for="Repeat Task">
                        <v-select v-model="taskLocal.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"  :options="type" />
                    </b-form-group>
                    <validation-provider v-if="checkCustomDate(taskLocal)" #default="validationContext" name="Custom Date" rules="required">
                        <b-form-group label="Custom Date" label-for="custom-date">
                            <flat-pickr v-model="taskLocal.custom_date" :config="config" class="form-control" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- <b-form-group label="Permission">
                        <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="selected"
                            :options="options"
                            name="flavour-1"
                            class="demo-inline-spacing"
                        />
                    </b-form-group> -->

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                            {{ taskLocal.id ? 'Update' : 'Add ' }}
                        </b-button>
                        <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                            Reset
                        </b-button> -->
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInvalidFeedback,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    url
} from '@validations'
import {
    avatarText
} from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
    toRefs
} from '@vue/composition-api'
import {
    quillEditor
} from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import axios from 'axios'
import {
    serverUri
} from '@/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {getUserData} from '@/auth/utils'

export default {
    components: {
        // BSV
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BAvatar,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormInvalidFeedback,

        // 3rd party packages
        vSelect,
        flatPickr,
        quillEditor,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
        ToastificationContent
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isTaskHandlerSidebarActive',
        event: 'update:is-task-handler-sidebar-active',
    },
    props: {
        isTaskHandlerSidebarActive: {
            type: Boolean,
            required: true,
        },
        task: {
            type: Object,
            required: true,
        },
        clearTaskData: {
            type: Function,
            required: true,
        },
        taskTags: {
            type: Array
        }
    },
    methods: {
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },


        checkCustomDate(tlocal)
        {
            if(tlocal.type != undefined)
            {
                return tlocal.type.text == 'custom' ? true : false

            } else {

                return false;
            }
        }
      
    },
    data() {
        return {
            required,
            email,
            url,
            config : {
                minDate : (new Date()).setDate((new Date()).getDate() + 1)
            },
            userlist: [{
                fullName: '',
                key: 0
            }],
            option: [{
                option: 'No'
            }, {
                option: 'Yes'
            }],
            status: ['pending', 'in progress','completed', 'cancelled'],
            type: [{
                text: 'no repeat', label: 'No Repeat'
            }, {
                text: 'daily', label: 'Daily'
            }, {
                text: 'weekly', label: 'Weekly'
            }, {
                text: 'monthly', label: 'Monthly'
            },
            {
               text: 'yearly', label: 'Yearly'
            },
            {
               text: 'custom', label: 'Custom'
            }],
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            },
            selected: [],
            options: [
                { text: 'manage-user', value: 'manage-user' },
                { text: 'manage-global-setting', value: 'manage-global-setting' },
                { text: 'manage-driver', value: 'manage-driver' },
                { text: 'manage-fleet', value: 'manage-fleet' },
                { text: 'manage-schedule', value: 'manage-schedule' },
                { text: 'manage-gf-statement', value: 'manage-gf-statement' },
                { text: 'manage-kpi', value: 'manage-kpi' },
                { text: 'manage-payroll', value: 'manage-payroll' },
                { text: 'manage-payroll-setting', value: 'manage-payroll-setting' },
                { text: 'manage-task', value: 'manage-task' },
                { text: 'manage-company', value: 'manage-company' },
                { text: 'manage-utilities', value: 'manage-utilities' },
                { text: 'manage-new', value: 'manage-new' },
            ],
            
        }
    },
    
    mounted() {
        console.log('the company id is ' , getUserData().company_id);
        axios.get(`${serverUri}/users/alluser/${getUserData().company_id}`).then(res => {
            if(!getUserData().role || !getUserData().role_id){
                this.userlist.push({
                    fullName: getUserData().full_name,
                    key: getUserData().id
                });
            }else{
                res.data.map(item => {
                    this.userlist.push({
                        fullName: item.name,
                        key: item.id
                    });
                })
            }
            
        })
    },
    setup(props, {emit}) {
        const {
            taskLocal,
            resetTaskLocal,

            // UI
            assigneeOptions,
            onSubmit,
            resolveAvatarVariant,
        } = useTaskHandler(toRefs(props), emit)

        const {
            refFormObserver,
            getValidationState,
            resetForm,
            clearForm,
        } = formValidation(resetTaskLocal, props.clearTaskData)

        const editorOption = {
            modules: {
                toolbar: '#quill-toolbar',
            },
            placeholder: 'Write your description',
        }

        return {
            // Add New
            taskLocal,
            onSubmit,
            assigneeOptions,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,

            // UI
            editorOption,
            resolveAvatarVariant,

            // Filter/Formatter
            avatarText,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style><style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
    ::v-deep .vs__dropdown-toggle {
        padding-left: 0;
    }
}

#quil-content ::v-deep {
    >.ql-container {
        border-bottom: 0;
    }

    +#quill-toolbar {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

.task_form_add .form-control 
{
    color: #645d76 !important;
}
</style>
