<template>
  <b-modal v-model="showModal" title="Company Details" hide-footer>
    <!-- Informational message for the user -->
    <div class="mb-4">
      <b-alert show variant="info">
        <h4 class="alert-heading">Welcome to the Setup Process!</h4>
        <p>
          To unlock all the features and get the full experience of our app, please register your company. 
          Provide accurate contact details below. We will reach out to confirm your company profile and finalize the setup.
        </p>
        <hr>
        <p class="mb-0">
          Your information is crucial for a tailored and effective setup. Thank you for taking the time to ensure its accuracy!
        </p>
      </b-alert>
    </div>
    
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Phone Number:" label-for="phone-input">
        <b-form-input id="phone-input" v-model="userPhoneNumber" required type="tel"></b-form-input>
      </b-form-group>
      <b-form-group label="Company Name:" label-for="company-input">
        <b-form-input id="company-input" v-model="companyName" required></b-form-input>
      </b-form-group>
      <b-form-group label="Company Email:" label-for="email-input">
        <b-form-input id="email-input" v-model="companyEmail" required type="email"></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { BModal, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import {mapActions} from 'vuex';
import todoStoreModule from './todoStoreModule';
import store from '@/store/index';
import {getUserData} from '@/auth/utils';

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
     
      userPhoneNumber: '',
      companyName: '',
      companyEmail: '',
    };
  },
  created(){
    if (!store.hasModule('app-todo')) store.registerModule('app-todo', todoStoreModule);
  },
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onSubmit() {
       store.dispatch('app-todo/submitCompanyDetails', {
        phoneNumber: this.userPhoneNumber,
        name: this.companyName,
        email: this.companyEmail,
        id: getUserData().company_id || null,
        userId: getUserData().id,
      })
      .then(response => {
        this.$emit('submission-result', response);
        this.$emit('update:showModal', false);
        console.log('Response from action:', response);
      })
      .catch(error => {
        // Handle error
        console.error('Error from action:', error);
        this.$emit('submission-result', error);
        
      });

    },
    closeModal() {
      this.$emit('update:showModal', false);
    }
  },
  
};
</script>
