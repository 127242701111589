<template>
<div class="sidebar-left">
    <div class="sidebar">
        <div class="sidebar-content todo-sidebar">
            <div class="todo-app-menu">
                <div class="add-task">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')">
                        Add Task
                    </b-button>
                </div>
                <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area">
                    <!-- Filters -->
                    <b-list-group class="list-group-filters">
                        <b-list-group-item v-for="filter in taskFilters" :key="filter.title + $route.path" :to="filter.route" :active="isDynamicRouteActive(filter.route)" @click="$emit('close-left-sidebar')">
                            <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                            <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- Tags -->
                    <div class="mt-3 px-2 d-flex justify-content-between">
                        <h6 class="section-label mb-1">
                            Tags
                        </h6>
                        <div>
                            <feather-icon class="cursor-pointer" v-b-modal.modal-add icon="PlusIcon" />
                            <feather-icon class="cursor-pointer" v-b-modal.modal-delete icon="MinusIcon" style="margin-left: 10px;"/>
                        </div>
                    </div>

                    <b-list-group class="list-group-labels">
                        <b-list-group-item v-for="tag in taskTags" :key="tag.title + $route.path" :to="tag.route" :active="isDynamicRouteActive(tag.route)" @click="$emit('close-left-sidebar')">
                            <span class="bullet bullet-sm mr-1" :style="{background: `${tag.color}`}" />
                            <span>{{ tag.label }}</span>
                        </b-list-group-item>
                    </b-list-group>

                </vue-perfect-scrollbar>
            </div>
        </div>
    </div>

    <!-- modal tag add -->
    <b-modal id="modal-add" centered title="Create Tag" ok-only @ok="onSave" ok-title="Save">
        <b-form-group label="Tag Name" label-for="tag-name">
            <b-form-input id="tag-name" placeholder="Enter Tag Name" v-model="tagTitle"/>
        </b-form-group>
        <b-form-group label="Tag Color" label-for="tag-color">
            <b-form-input type="color" id="tag-name" placeholder="Enter Tag Name" v-model="tagColor"/>
            <!-- <v-select
                v-model="tagColor"
                :dir="
                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                "
                label="label"
                :options="option"
            /> -->
        </b-form-group>
    </b-modal>

    <!-- modal tag delete -->
    <b-modal id="modal-delete" centered title="Delete Tag" ok-only @ok="onDelete" ok-title="Delete" no-close-on-backdrop>
        <b-form-group label="Tag Name" label-for="tag-name">
            <v-select
                v-model="selectedTag"
                :dir="
                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                "
                label="label"
                :options="taskTags"
            />
        </b-form-group>
    </b-modal>
</div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
    BModal,
    VBModal,
    BButton,
    BFormInput,
    BFormGroup,
    BListGroup,
    BListGroupItem
} from 'bootstrap-vue'
import axios from "axios";
import { serverUri } from "@/config";
import vSelect from "vue-select";
import {
    isDynamicRouteActive
} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BButton,
        BModal,
        BListGroup,
        BFormInput,
        BFormGroup,
        BListGroupItem,
        VuePerfectScrollbar,
        vSelect,
        ToastificationContent
    },
    data() {
        return {
            tagTitle: "",
            tagColor: "",
            option: [],
            selectedTag: null
        }
    },
    props: ["taskTags"],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    methods: {
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },
        onSave() {
            if (this.tagTitle && this.tagColor)
                axios
                    .post(`${serverUri}/todo/tag/create`, {
                        tagTitle: this.tagTitle,
                        tagColor: this.tagColor
                    })
                    .then((res) => {
                        this.$emit('fetchTagsEvent');
                        this.showToast('success', 'Successfully!', 'Tag has been save.');
                        this.tagTitle = '';
                        this.tagColor = '';
                    });
            else this.showToast('warning', 'Something Empty!', '');
        },
        onDelete() {
            if (this.selectedTag)
                axios
                    .delete(`${serverUri}/todo/tag/delete/${this.selectedTag.title}`).then(res => {
                        this.$emit('fetchTagsEvent');
                        this.showToast('success', 'Successfully!', 'Tag has been deleted.');
                        this.selectedTag = null;
                    })
            else this.showToast('warning', 'Please select tag!', '')
        }
    },
    setup() {
        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
        }

        const taskFilters = [{
                title: 'All Tasks',
                icon: 'MailIcon',
                route: {
                    name: 'apps-todo'
                }
            },
            {
                title: 'Pending',
                icon: 'CheckIcon',
                route: {
                    name: 'apps-todo-filter',
                    params: {
                        filter: 'pending'
                    }
                }
            },
            {
                title: 'In Progress',
                icon: 'StarIcon',
                route: {
                    name: 'apps-todo-filter',
                    params: {
                        filter: 'in progress'
                    }
                }
            },
            {
                title: 'Completed',
                icon: 'FlagIcon',
                route: {
                    name: 'apps-todo-filter',
                    params: {
                        filter: 'completed'
                    }
                }
            },
            /*{
                title: 'Cancelled',
                icon: 'ScissorsIcon',
                route: {
                    name: 'apps-todo-filter',
                    params: {
                        filter: 'cancelled'
                    }
                }
            },*/
        ]

        return {
            perfectScrollbarSettings,
            taskFilters,
            isDynamicRouteActive,
        }
    },
}
</script>

<style>

</style>
